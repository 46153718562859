//@ts-nocheck

import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Title } from '../texts';
import { isSmallScreen } from '../../utils/helpers/isSmallScreen';
import { useUserContext } from '../../providers/UserProvider';
import { Avatar } from 'react-native-paper';
import { getUsernameOrPreferredUsername } from '../../utils/helpers/validations/getUsernameOrPreferredUsername';
import MainCardContainer from './MainCardContainer';

interface ServiceCardInterface {
  toggleModal: () => void;
  isModalVisible?: boolean;
}

const ServiceCard = ({ toggleModal, isModalVisible }: ServiceCardInterface) => {
  const userContext = useUserContext();
  return (
    <View style={styles.touchableContainer}>
      <TouchableOpacity onPress={() => toggleModal()}>
        <MainCardContainer>
          <View>
            <Avatar.Text
              size={70}
              label={getUsernameOrPreferredUsername(userContext.userState.profile)[0].toUpperCase()}
            />
          </View>
          <View style={styles.subContainer}>
            <View>
              <Title h2 black mvXS>
                {userContext.userState.profile?.currentDepartment.name?.fr ||
                  userContext.userState.profile?.currentDepartment.name?.FR}
              </Title>
              <Title h3 mvXS>
                {getUsernameOrPreferredUsername(userContext.userState.profile)} -{' '}
                {userContext.userState.profile?.job?.acronym}
              </Title>
            </View>
            <View>
              {isModalVisible ? (
                <AntDesign name="close" size={24} color="gray" />
              ) : (
                <AntDesign name="right" size={24} color="gray" />
              )}
            </View>
          </View>
        </MainCardContainer>
      </TouchableOpacity>
    </View>
  );
};

export default ServiceCard;

const styles = StyleSheet.create({
  touchableContainer: {
    position: 'absolute',
    top: isSmallScreen() ? 70 : 120,
    alignSelf: 'center',
    width: '95%',
    zIndex: 999,
  },
  subContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 10,
  },
});

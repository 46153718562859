import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Title } from '../../../components/texts';
import { Radius } from '../../../styles/theme';
import { MaterialIcons } from '@expo/vector-icons';
import { ROUTES } from '../../../router/Routes';
import { useNavigation } from '@react-navigation/native';
import { usePositionsContext } from '../../../providers/PositionsProvider';
import { NoActivityIndicator } from '../../../components/feedbacks/NoActivityIndicator';
import { Container } from '../../../layouts';

const Item = ({ title, occupedBy, id }: any) => {
  const { navigate } = useNavigation();
  return (
    <TouchableOpacity onPress={() => navigate(ROUTES.LEAVE_BED_SCREEN.name, { positionId: id })}>
      <View style={styles.itemContainer}>
        <Container flexCol alignStart justifyStart>
          <Container alignCenter justifyStart flexRow>
            <Image source={require('../../../assets/images/requests/iconUser.png')} style={{ width: 35, height: 35 }} />
            <Title h3 primary mvXS mhS>
              {occupedBy}
            </Title>
          </Container>
          <Container alignCenter justifyStart flexRow>
            <Image
              source={require('../../../assets/images/requests/iconService.png')}
              style={{ width: 35, height: 35 }}
            />
            <Title h3 mvXS mhS>
              {title}
            </Title>
          </Container>
        </Container>
        <Container alignEnd flex={1}>
          <MaterialIcons name="logout" size={24} color="red" />
        </Container>
      </View>
    </TouchableOpacity>
  );
};

const BedsOverview = () => {
  const positionsContext = usePositionsContext();

  React.useEffect(() => {
    positionsContext.getDepartmentPositions();
  }, []);

  const renderItem = (item: any) => {
    return (
      <Item title={`${item.room?.name} - ${item.name}`} occupedBy={item.patient?.name} key={item.id} id={item.id} />
    );
  };

  return (
    <View>
      <Title h2 mvS>
        Lits occupés : {positionsContext.positionsState.positions.filter((item: any) => item.occupied === true).length}/
        {positionsContext.positionsState.positions.length}
      </Title>
      <View>
        {positionsContext.positionsState.positions.filter((item: any) => item.occupied === true).length === 0 ? (
          <NoActivityIndicator />
        ) : (
          <></>
        )}
        {positionsContext.positionsState.positions
          .filter((item: any) => item.occupied === true)
          .map((item: any) => renderItem(item))}
      </View>
    </View>
  );
};

export default BedsOverview;

const styles = StyleSheet.create({
  itemContainer: {
    backgroundColor: '#ffffff',
    borderRadius: Radius.SMALL,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 20,
    marginTop: 10,
  },
});

import { gql } from '@apollo/client';

const getDepartmentHistory = gql`
  query DepartmentHistory($filters: FilterObjectInput, $departmentId: Int!) {
    DepartmentHistory(filters: $filters, departmentId: $departmentId) {
      type
      id
      name
      status
      datetime
      picture_type
      picture_id

      caregiver {
        name
        firstname
        user {
          preferredUsername
          username
        }
      }
      position {
        id
        name
        room {
          id
          name
        }
      }
    }
  }
`;

export default getDepartmentHistory;

import React from 'react';
import { GiftedChat, InputToolbar } from 'react-native-gifted-chat';
import { useUserContext } from '../../providers/UserProvider';
import { useChatContext } from '../../providers/ChatProvider';
import { Colors } from '../../styles/theme';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';
import { isSmallScreen } from '../../utils/helpers/isSmallScreen';
import { CustomBubble } from '../../components/giftedChat/customBubble';
import { customChatComposer } from '../../components/giftedChat/customChatComposer';

const HospiTeam = () => {
  const userContext = useUserContext();
  const insets = useSafeAreaInsets();
  const chatContext = useChatContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);

  const onSend = React.useCallback(async (messages = []) => {
    GiftedChat.append(chatContext.chatState.messages, messages);
    await chatContext.setNewMessage(messages);
    await chatContext.postMessage({ body: messages[0].text });
  }, []);

  const loadMoreMessages = async () => {
    if (isLoading) return;
    await chatContext.getOldMessages(currentPage + 1);
    setCurrentPage((page) => page + 1);
    setIsLoading(false);
  };

  const renderBubble = (props: any) => {
    return (
      <CustomBubble
        {...props}
        wrapperStyle={{
          right: {
            backgroundColor: Colors.PRIMARY_DARK,
            paddingLeft: 15,
          },
          left: {
            backgroundColor: 'white',
            paddingRight: 15,
          },
        }}
      />
    );
  };

  const customInputToolbar = (props: any) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          backgroundColor: 'white',
          borderTopColor: '#E8E8E8',
          borderTopWidth: 1,
        }}
        placeholder="Message"
        label={'Envoyer'}
      />
    );
  };

  const getOffsetBottom = () => {
    if (Platform.OS === 'ios') {
      if (isSmallScreen()) {
        return insets.bottom + 70;
      }
      return insets.bottom + 65;
    } else {
      return insets.bottom;
    }
  };

  const isCloseToTop = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
    const paddingToTop = 80;
    return contentSize.height - layoutMeasurement.height - paddingToTop <= contentOffset.y;
  };

  return (
    <GiftedChat
      messages={chatContext.chatState.messages}
      onSend={(messages) => onSend(messages)}
      locale={'FR-fr'}
      user={{
        _id: userContext.userState.profile.id,
        name: userContext.userState.profile.preferredUsername,
      }}
      renderUsernameOnMessage={true}
      renderBubble={renderBubble}
      renderInputToolbar={customInputToolbar}
      bottomOffset={getOffsetBottom()}
      renderComposer={customChatComposer}
      listViewProps={{
        scrollEventThrottle: 400,
        onScroll: async ({ nativeEvent }: any) => {
          if (isCloseToTop(nativeEvent)) {
            setIsLoading(true);
            await loadMoreMessages();
          }
        },
      }}
    />
  );
};

export { HospiTeam };

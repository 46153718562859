import React from 'react';
import { StyleSheet, View } from 'react-native';
import MainLayout from '../../../layouts/dashboard/MainLayout';
import { Button } from '../../../components/buttons';
import ModuleContentView from '../../../layouts/dashboard/ModuleContentView';
import ActivityDetails from '../../../modules/dashboard/requests/ActivityDetails.module';
import GeneralDetails from '../../../modules/dashboard/requests/GeneralDetails.module';
import { useDemandsContext } from '../../../providers/DemandsProvider';
import { useNavigation, useRoute } from '@react-navigation/native';
import { StatusEnum } from '../../../types/enums/StatusEnum';
import { ROUTES } from '../../../router/Routes';
import { Title } from '../../../components/texts';

const DetailsRequestScreen = () => {
  const demandsContext = useDemandsContext();
  const [mode, setMode] = React.useState('general');
  const [request, setRequest] = React.useState<any>(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const route = useRoute();
  const { id } = route.params as any;
  const { navigate } = useNavigation();

  const takeDemand = async (id: number) => {
    setLoading(true);
    const updatedDemandResponse = await getDemandById(id);

    if (updatedDemandResponse.status !== StatusEnum.NEW && updatedDemandResponse.status !== StatusEnum.BOOSTED) {
      setLoading(false);
      setError('Un autre soignant s occupe déjà de cette demande, vous pouvez cependant la terminer.');
      return;
    }
    const response = await demandsContext.takeDemand(id).catch((error: any) => {
      setLoading(false);
    });
    if (response.data.takeDemand) {
      await getDemandById(id);
      demandsContext.getMyServiceDemands();
      setLoading(false);
      navigate(ROUTES.HOME_SCREEN.name);
    }
  };

  const finishDemand = async (id: number) => {
    setLoading(true);
    const updatedDemandResponse = await getDemandById(id);
    if (updatedDemandResponse.status !== StatusEnum.PROCESSING) {
      setLoading(false);
      setError('Un autre soignant a déjà terminé cette demande');
      return;
    }
    const response = await demandsContext.finishDemand(id).catch(() => {
      setLoading(false);
    });

    if (response.data.finishDemand.status === StatusEnum.TERMINATED) {
      await getDemandById(id);
      demandsContext.getMyServiceDemands();
      setLoading(false);
      navigate(ROUTES.HOME_SCREEN.name);
    }
  };

  const getDemandById = (id: number) => {
    return demandsContext
      .getDemandById(id)
      .then((response: any) => {
        setRequest(response.data.getDemand);
        return response.data.getDemand;
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const getActionButton = (status: StatusEnum) => {
    switch (status) {
      case StatusEnum.NEW:
      case StatusEnum.BOOSTED:
        return (
          <View style={{ marginTop: 30 }}>
            <Button label={"Je m'en occupe"} onPress={() => takeDemand(id)} loading={loading} />
          </View>
        );
      case StatusEnum.PROCESSING:
        return (
          <View style={{ marginTop: 30 }}>
            <Button success label={"C'est fait"} onPress={() => finishDemand(id)} loading={loading} />
          </View>
        );
      case StatusEnum.TERMINATED:
        return (
          <View style={{ marginTop: 30 }}>
            <Button disable label={'Demande terminée.'} />
          </View>
        );
      case StatusEnum.CANCELLED:
        return (
          <View style={{ marginTop: 30 }}>
            <Button disable label={'Demande annulée'} />
          </View>
        );
    }
  };

  React.useEffect(() => {
    getDemandById(id);
  }, [id]);

  return (
    <MainLayout backButton requestCardHeaderData={request} backToTop>
      <ModuleContentView>
        {
          /**
           *      <View style={styles.buttonsContainer}>
           *           <Button label={'General'} disable={mode === 'activity'} flex_1 onPress={() => setMode('general')} />
           *           <Button label={'Historique'} flex_1 disable={mode === 'general'} onPress={() => setMode('activity')} />
           *         </View>
           */
        }

        {error !== '' ? (
          <Title h3 secondary center>
            {error}
          </Title>
        ) : (
          <></>
        )}
        {mode === 'general' ? (
          <GeneralDetails
            status={request.status}
            priority={request.priority}
            commentary={request.comment}
            intensity={request.intensity}
            caregiverUsername={request.takeBy?.user?.username}
            takeAt={request.takeAt}
            bodyPart={request.bodyPart}
          />
        ) : (
          <ActivityDetails />
        )}
        {getActionButton(request.status)}
      </ModuleContentView>
    </MainLayout>
  );
};

export { DetailsRequestScreen };

const styles = StyleSheet.create({
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginVertical: 20,
  },
});

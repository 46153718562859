import React from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { Paragraph, Title } from '../../../components/texts';
import { StatusEnum } from '../../../types/enums/StatusEnum';
import { PriorityEnum } from '../../../types/enums/PriorityEnum';
import { Colors } from '../../../styles/theme';
import { getPriority } from '../../../utils/helpers/getPriority';
import { getStatus } from '../../../utils/helpers/getStatus';

interface GeneralDetailsInterface {
  commentary?: string;
  status: StatusEnum;
  priority: PriorityEnum;
  intensity?: number;
  caregiverUsername?: string;
  takeAt: string;
  bodyPart?: any;
}

const GeneralDetails = ({
  commentary,
  status,
  priority,
  intensity,
  caregiverUsername,
  takeAt,
  bodyPart,
}: GeneralDetailsInterface) => {
  const dateTakeAt = new Date(takeAt);

  const renderComment = (commentary: string | undefined) => {
    if (commentary) {
      return (
        <View>
          <Title h2>Commentaire patient</Title>
          <ScrollView style={styles.commentaryContainer}>
            <Paragraph grey>{commentary}</Paragraph>
          </ScrollView>
        </View>
      );
    }
  };

  const renderIntensity = (intensity: number | undefined) => {
    if (intensity) {
      return (
        <View style={styles.detailsSubContainer}>
          <View style={styles.detailsTitleContainer}>
            <Image source={require('../../../assets/images/requests/Intensity.png')} />
            <Title h2>Intensité</Title>
          </View>
          <Title h3 primary>
            {intensity}/10
          </Title>
        </View>
      );
    }
  };

  const renderBodyPart = (bodyPart: any) => {
    if (bodyPart) {
      return (
        <View style={styles.detailsSubContainer}>
          <View style={styles.detailsTitleContainer}>
            <Image source={require('../../../assets/images/requests/Intensity.png')} />
            <Title h2>Partie du corps</Title>
          </View>
          <Title h3 primary>
            {bodyPart.name}
          </Title>
        </View>
      );
    }
  };

  const renderTakeBy = () => {
    if (caregiverUsername) {
      return (
        <View>
          <Title h2>Demande prise en charge par </Title>
          <View style={styles.detailsContainer}>
            <View style={styles.detailsSubContainer}>
              <View style={styles.detailsTitleContainer}>
                <Image
                  source={require('../../../assets/images/requests/nurse.png')}
                  style={{ width: 50, height: 50 }}
                />
                <Title h2 mhM>
                  {caregiverUsername} à {dateTakeAt.getHours()}h
                  {dateTakeAt.getMinutes() < 10 ? `0${dateTakeAt.getMinutes()}` : dateTakeAt.getMinutes()}
                </Title>
              </View>
            </View>
          </View>
        </View>
      );
    }
  };

  return (
    <View>
      {renderComment(commentary)}
      <View>
        <Title h2>Détails</Title>
        <View style={styles.detailsContainer}>
          <View style={styles.detailsSubContainer}>
            <View style={styles.detailsTitleContainer}>
              <Image source={require('../../../assets/images/requests/Statut.png')} />
              <Title h2>Statut</Title>
            </View>
            <Title h3 primary>
              {getStatus(status)}
            </Title>
          </View>
          <View style={styles.detailsSubContainer}>
            <View style={styles.detailsTitleContainer}>
              <Image source={require('../../../assets/images/requests/Flag.png')} />
              <Title h2>Priorité</Title>
            </View>
            <Title h2 primary>
              {getPriority(priority)}
            </Title>
          </View>
          {renderIntensity(intensity)}
          {renderBodyPart(bodyPart)}
        </View>
      </View>
      {status === StatusEnum.PROCESSING ? renderTakeBy() : null}
    </View>
  );
};

export default GeneralDetails;

const styles = StyleSheet.create({
  commentaryContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 30,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    shadowOpacity: 1,
    shadowRadius: 11.95,
    elevation: 5,
    padding: 15,
  },
  detailsContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 30,
    shadowColor: '#b1b1b1',
    shadowOffset: {
      width: 9,
      height: 9,
    },
    shadowOpacity: 0.3,
    shadowRadius: 11.95,
    elevation: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  detailsSubContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  detailsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});

import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Title } from '../../../components/texts';
import RequestsBadge from '../../../components/badges/RequestsBadge';
import RequestCard from '../../../components/cards/RequestCard';
import { StatusEnum } from '../../../types/enums/StatusEnum';
import { useNavigation } from '@react-navigation/native';
import { ROUTES } from '../../../router/Routes';
import { useDemandsContext } from '../../../providers/DemandsProvider';
import { usePositionsContext } from '../../../providers/PositionsProvider';
import PositionRequestCard from '../../../components/cards/PositionRequestCard';
import { getPriorityValue } from '../../../utils/helpers/getPriority';
import { Container } from '../../../layouts';

interface IRequestsListsModule {
  fromLockScreen?: boolean;
}

const RequestsListsModule = ({ fromLockScreen }: IRequestsListsModule) => {
  const demandsContext = useDemandsContext();
  const positionsContext = usePositionsContext();
  const { navigate } = useNavigation();
  const isWeb = Platform.OS === 'web';

  const renderItem = (request: any) => {
    return (
      <TouchableOpacity
        onPress={() =>
          fromLockScreen
            ? navigate(ROUTES.PIN_SCREEN_WEB.name)
            : navigate(ROUTES.REQUEST_DETAILS_SCREEN.name, { id: request.id })
        }
        key={request.id}
        style={Platform.OS === 'web' ? styles.requestContainerWeb : null}
      >
        <RequestCard
          icon={request.category.icon ? request.category.icon : null}
          title={request.category.name ? request.category.name.fr : 'null'}
          date={request.createdAt}
          haveComment={request.comment}
          status={request.status.toUpperCase()}
          priority={request.priority.toUpperCase()}
          category={request.category}
          id={request.id}
          position={request.position ? request.position : 'null'}
          fromLockScreen={fromLockScreen}
        />
      </TouchableOpacity>
    );
  };

  return (
    <Container>
      <View>
        <Container justifyBetween flexRow alignCenter>
          <Title h2>En attente de traitement</Title>
          <RequestsBadge
            count={
              demandsContext.demandsState?.demands?.filter(
                (request: any) => request.status === StatusEnum.NEW || request.status === StatusEnum.BOOSTED
              ).length
            }
          />
        </Container>
        <Container>
          {positionsContext.positionsState?.positionsRequests.map((request: any, key: number) => (
            <TouchableOpacity
              onPress={() => navigate(ROUTES.NEW_PATIENT_SCREEN.name, { positionRequestId: request.id })}
              key={key}
              style={Platform.OS === 'web' ? styles.requestContainerWeb : null}
            >
              <PositionRequestCard
                date={request.createdAt}
                position={request.position.name}
                room={request.position.room.name}
              />
            </TouchableOpacity>
          ))}
        </Container>
        <Container>
          {demandsContext.demandsState.demands
            .filter((request: any) => request.status === StatusEnum.NEW || request.status === StatusEnum.BOOSTED)
            .sort((a: any, b: any) => (getPriorityValue(a.priority) < getPriorityValue(b.priority) ? 1 : -1))
            .map((request: any) => renderItem(request))}
        </Container>
      </View>
      <Container>
        <Container justifyBetween flexRow alignCenter>
          <Title h2>En cours de traitement</Title>
          <RequestsBadge
            count={
              demandsContext.demandsState.demands.filter((request: any) => request.status === StatusEnum.PROCESSING)
                .length
            }
          />
        </Container>
        <Container>
          {demandsContext.demandsState.demands
            .filter((request: any) => request.status === StatusEnum.PROCESSING)
            .map((request: any) => renderItem(request))}
        </Container>
      </Container>
    </Container>
  );
};

export default RequestsListsModule;

const styles = StyleSheet.create({
  requestContainerWeb: {
    width: 400,
    margin: 5,
  },
});

import React from 'react';
import { Image, View } from 'react-native';
import { Title } from '../texts';

export const NoActivityIndicator = () => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 50,
      }}
    >
      <Image source={require('../../assets/images/requests/noRequest.png')} />
      <Title h2 center>
        Vous êtes a jour
      </Title>
      <Title h3 center>
        Il n y a rien de nouveau ici
      </Title>
    </View>
  );
};

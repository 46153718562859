import React from 'react';
import { StyleSheet, View } from 'react-native';

const ModuleContantView = ({ children, backgroundColor }: any) => {
  return <View style={[styles.container, { backgroundColor }]}>{children}</View>;
};

export default ModuleContantView;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: 50,
    padding: 10,
    paddingBottom: 50,
  },
});

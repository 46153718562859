import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../styles/theme';

interface IContainer {
  children: React.ReactNode;
  flexRow?: boolean;
  flexRowReverse?: boolean;
  flexCol?: boolean;
  flex?: number;
  alignCenter?: boolean;
  alignStart?: boolean;
  alignEnd?: boolean;
  justifyCenter?: boolean;
  justifyStart?: boolean;
  justifyEnd?: boolean;
  justifyBetween?: boolean;
  justifyAround?: boolean;
  primary?: boolean;
  secondary?: boolean;
  marginVertical?: number;
  marginHorizontal?: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
  flexWrap?: boolean;
  w100?: boolean;
  height?: number | string;
  width?: number | string;
  radius?: number;
  backgroundColor?: string;
  customStyle?:string;
}

export const Container = ({
  children,
  flexRow,
  flexCol,
  flex,
  alignCenter,
  alignStart,
  alignEnd,
  justifyEnd,
  justifyCenter,
  justifyAround,
  justifyBetween,
  primary,
  secondary,
  marginVertical,
  marginHorizontal,
  paddingVertical,
  paddingHorizontal,
  flexWrap,
  w100,
  height,
  width,
  radius,
  backgroundColor,
  flexRowReverse,
  customStyle
}: IContainer) => {
  const containerStyles: Array<any> = [styles.baseStyle, customStyle];

  if (flexRow) containerStyles.push(styles.flexRow);
  if (flexRowReverse) containerStyles.push(styles.flexRowReverse);
  if (flexCol) containerStyles.push(styles.flexCol);
  if (flex) containerStyles.push({ flex: flex });
  if (alignCenter) containerStyles.push(styles.alignCenter);
  if (alignStart) containerStyles.push(styles.alignStart);
  if (alignEnd) containerStyles.push(styles.alignEnd);
  if (justifyEnd) containerStyles.push(styles.justifyEnd);
  if (justifyCenter) containerStyles.push(styles.justifyCenter);
  if (justifyAround) containerStyles.push(styles.justifyAround);
  if (justifyBetween) containerStyles.push(styles.justifyBetween);
  if (primary) containerStyles.push({ backgroundColor: Colors.PRIMARY });
  if (secondary) containerStyles.push({ backgroundColor: Colors.SECONDARY });
  if (marginVertical) containerStyles.push({ marginVertical });
  if (marginHorizontal) containerStyles.push({ marginHorizontal });
  if (paddingVertical) containerStyles.push({ paddingVertical });
  if (paddingHorizontal) containerStyles.push({ paddingHorizontal });
  if (width) containerStyles.push({ width });
  if (height) containerStyles.push({ height });
  if (radius) containerStyles.push({ borderRadius: radius });
  if (flexWrap) containerStyles.push(styles.flexWrap);
  if (w100) containerStyles.push(styles.w100);
  if (backgroundColor) containerStyles.push({ backgroundColor });

  return <View style={containerStyles}>{children}</View>;
};

const styles = StyleSheet.create({
  baseStyle: {
    display: 'flex',
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexRowReverse: {
    flexDirection: 'row-reverse',
  },
  flexCol: {
    flexDirection: 'column',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyAround: {
    justifyContent: 'space-between',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  w100: {
    width: '100%',
  },
});

import React from 'react';
import { Image, View } from 'react-native';
import { Colors } from '../../styles/theme';
import { EvilIcons } from '@expo/vector-icons';
import { Title } from '../texts';
import MainCardContainer from './MainCardContainer';
import { Container } from '../../layouts';
import { Label } from '../texts/Label';

interface requestCardInterface {
  date: string;
  position: string;
  room: string;
}

const PositionRequestCard = ({ date, position, room }: requestCardInterface) => {
  const takeAt = new Date(date);

  return (
    <MainCardContainer backgroundColor={Colors.REQUEST_CARDS.NEW_PATIENT.light}>
      <Container flexRow alignCenter justifyCenter flex={1}>
        <Image
          source={require('../../assets/images/requests/newUser.png')}
          style={{
            width: 70,
            height: 70,
          }}
        />
      </Container>
      <Container flexCol flex={2} alignStart justifyBetween>
        <View>
          <Title h2 mvXS color={Colors.REQUEST_CARDS['NEW_PATIENT'].dark}>
            {room} - {position}
          </Title>
        </View>
        <Label>Nouveau patient</Label>
        <Container flexRow alignCenter justifyBetween>
          <Container flexRow alignCenter justifyStart>
            <EvilIcons name="clock" size={24} color="black" />
            <Title h3 mvXS>
              {takeAt.getHours()} : {takeAt.getMinutes() < 10 ? `0${takeAt.getMinutes()}` : takeAt.getMinutes()}
            </Title>
          </Container>
        </Container>
      </Container>
    </MainCardContainer>
  );
};

export default PositionRequestCard;

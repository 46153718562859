import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { Colors, Spacing } from '../../styles/theme/';

interface TitleInterface {
  secondary?: boolean;
  primary?: boolean;
  black?: boolean;
  color?: string | null;
  children: React.ReactNode;
  center?: boolean;
  left?: boolean;
  right?: boolean;
  white?: boolean;
  mvS?: boolean;
  mvXS?: boolean;
  mvM?: boolean;
  mvL?: boolean;
  mhS?: boolean;
  mhM?: boolean;
  mhL?: boolean;
  mhXS?: boolean;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
}

export const Title: React.FunctionComponent<TitleInterface> = ({
  children,
  secondary,
  primary,
  black,
  color,
  center,
  left,
  right,
  white,
  mvS,
  mvXS,
  mvM,
  mvL,
  mhS,
  mhM,
  mhL,
  mhXS,
  h1,
  h2,
  h3,
}: TitleInterface) => {
  const TitleStyles: Array<{ [index: string]: string | number }> = [styles.h1, styles.default];

  if (h1) TitleStyles.push(styles.h1);
  if (h2) TitleStyles.push(styles.h2);
  if (h3) TitleStyles.push(styles.h3);
  if (secondary) TitleStyles.push(styles.secondary);
  if (primary) TitleStyles.push(styles.primary);
  if (white) TitleStyles.push(styles.white);
  if (black) TitleStyles.push(styles.black);
  if (color) TitleStyles.push({ color });
  if (center) TitleStyles.push(styles.center);
  if (left) TitleStyles.push(styles.left);
  if (right) TitleStyles.push(styles.right);
  if (mvL) TitleStyles.push(styles.mvL);
  if (mvM) TitleStyles.push(styles.mvM);
  if (mvS) TitleStyles.push(styles.mvS);
  if (mvXS) TitleStyles.push(styles.mvXS);
  if (mhL) TitleStyles.push(styles.mhL);
  if (mhM) TitleStyles.push(styles.mhM);
  if (mhS) TitleStyles.push(styles.mhS);
  if (mhXS) TitleStyles.push(styles.mhXS);

  return <Text style={TitleStyles}>{children}</Text>;
};

const styles = StyleSheet.create({
  h1: {
    fontSize: 24,
    fontWeight: '700',
    zIndex: 2,
    ...Spacing.MARGIN_VERTICAL_MEDIUM,
  },
  h2: {
    fontSize: 22,
    fontWeight: '500',
    ...Spacing.MARGIN_VERTICAL_MEDIUM,
  },
  h3: {
    fontSize: 20,
    fontWeight: '300',
    ...Spacing.MARGIN_VERTICAL_MEDIUM,
  },
  default: {
    color: Colors.GREY,
  },
  white: {
    color: Colors.WHITE,
  },
  black: {
    color: Colors.BLACK,
  },
  primary: {
    color: Colors.PRIMARY,
  },
  secondary: {
    color: Colors.SECONDARY,
  },
  center: {
    textAlign: 'center',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  mvL: {
    ...Spacing.MARGIN_VERTICAL_LARGE,
  },
  mvM: {
    ...Spacing.MARGIN_VERTICAL_MEDIUM,
  },
  mvS: {
    ...Spacing.MARGIN_VERTICAL_SMALL,
  },
  mvXS: {
    marginVertical: 5,
  },
  mhL: {
    ...Spacing.MARGIN_HORIZONTAL_LARGE,
  },
  mhM: {
    ...Spacing.MARGIN_HORIZONTAL_MEDIUM,
  },
  mhS: {
    ...Spacing.MARGIN_HORIZONTAL_SMALL,
  },
  mhXS: {
    marginHorizontal: 5,
  },
});
